:root {
  --material-transition-curve: cubic-bezier(0.4, 0, 0.2, 1);
}


.MuiDataGrid-main  {
  & > div:last-child:not([role]) {
    display: none;
  }
}
.MuiDateRangeCalendar-root  {
  & > div:first-child {
    display: none;
  }
}

.MuiDataGrid-cell {
  &.expand-action {
    cursor: pointer !important;
  }

  &:not(.MuiDataGrid-cell--editable) {
    cursor: not-allowed;
  }

  &.MuiDataGrid-cell--editable {
    cursor: text;
  }
}

.MuiDataGrid-columnHeaders {
  cursor: not-allowed;
}

.MuiDataGrid-row {

  &.completed {
    //background-color: rgba(0, 0, 0, 0.01);
    opacity: 0.3;
  }
}

.MuiDataGrid-columnHeader {

  &.wrap {
  .MuiDataGrid-columnHeaderTitle {
      text-wrap: pretty;
      line-height: normal;
      white-space: initial !important;
      text-align: center;
    }
  }

}

.overconsumed {
  background-color: rgba(255, 0, 0, 0.1);
}

